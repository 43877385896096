<template>
  <v-dialog
    v-model="account"
    persistent
    max-width="600px"
  >
    <v-card tile>
      <v-card-title class="pa-0 ma-0">
        <v-btn icon @click="cerrar()" style="position: absolute; right:15px; top: 5px">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text :class="{'px-10': $vuetify.breakpoint.mdAndUp}">
        <v-list>
          <v-list-item>
            <v-list-item-content class="text-center">
              <span 
                class="font-weight-bold primary--text"
                :class="{'text-h4': $vuetify.breakpoint.smAndUp, 'text-h5': $vuetify.breakpoint.xsOnly,}"
              >Gestión de Imágenes</span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-row class="d-block">
          <v-col cols="12" class="py-0">
            <span class="blue-grey--text text--darken-3 ">Gestione la imágen del producto </span> 
            <span class="primary--text font-weight-bold" v-text="product.art_des"></span> 
          </v-col>
          <v-col cols="12">
           
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-0">
            <VueFileAgent
              ref="FileImageProduct"
              v-model="data_imagenes"
              help-text="Arrastre o Suba una Nueva imagen del producto en formtao JPG"
              :accept="'.jpg'"
              :multiple="false"
              :deletable="true" 
              :theme="'list'"
              :error-text="{type: 'Formato no válido.'}"
              class="py-2 input-imagen"
              @beforedelete="fileDeleted($event)"
            />
          </v-col>
          <v-col cols="12" class="text-right pt-4">
            <v-btn
              color="success white--text"
              class="px-6"
              tile
              depressed
              small
              :disabled="!data_imagenes"
              :loading="uploadImagen"
              @click="changeImagen"
            >
              <span>Añadir</span>
              <v-icon size="28" right>mdi-image-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name:'ModalImagenes',
  props:{
    value: Boolean,
    product:{
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      account: this.value,
      data_imagenes: '',
      uploadImagen: false,
    }
  },
  watch: {
    account (val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value (val, oldVal) {
      if (val === oldVal) return

      this.account = val
    },
  },
  methods:{
    cerrar() {
      this.data_imagenes = ''
      this.account = false;
    },
    deleteImage() {
      this.$root.$confirm('¿Estás Seguro?', 'Se eliminara la imagen Permanentemente de nuestros Registros.');
    },
    fileDeleted(record) {
      // this.$refs.FileImageProduct.deleteFileRecord(record);
      this.data_imagenes = '';
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    async changeImagen() {
      
      if(this.data_imagenes.ext !== 'jpg') {
        return;
      }
      
      this.uploadImagen = true;
      try {        
        const imagen = await this.getBase64(this.data_imagenes.file)
        const {data} = await this.$api.post('articulos/cargarimagen', {
          imagen: imagen,
          cod: this.product.co_art
        });
        this.$emit('update');
        this.$root.$showAlert(data.message, 'success');
      } catch (error) {
          this.$root.$showAlert(
            'Lo sentimos, hubo un error al intentar guardar la imagen del producto.', 
            'error'
          );
      }
      this.uploadImagen = false;
      this.cerrar();
    },
    async addUser() {
      const valid = await this.$refs.USER_FORM.validate();
      if(valid) {
        try {
          this.loadingUser = true;
          this.userInfo.name = this.data.ven_des;
          // this.userInfo.email = this.data.email;
          this.userInfo.vendedor_id = this.data.id;
          const { data } = await this.$api({
            method: this.newUser ? 'post' : 'put',
            url: this.newUser ? 'users' : 'users/' + this.userInfo.id,
            data: this.newUser ? this.userInfo : {password: this.userInfo.password, status: this.userInfo.status}
            // method: newUser ? 'post' : 'put',
            // url: 'users',
            // data: this.userInfo,
          });

          this.loadingUser = false;
          this.cerrar();
          this.$parent.$emit('procesado');
          this.$root.$showAlert(data.message, 'success');
        } catch (error) {
            this.loadingUser = false;
            this.$root.$showAlert(
              'Lo sentimos, hubo un error al intentar crear el usuario.', 
              'error'
            );
        }
      }
    }
  },
  computed:{
    newUser() {
      return this.data.user === null 
    },
    check() {
      const {value, porcent} = passwordStrength(this.userInfo.password);

      return{
        level: value,
        porcent: porcent,
        color: porcent === 33 ? 'blue-grey' : porcent === 66 ? 'deep-orange' : porcent === 100 ? 'green accent-3' : 'blue-grey lighten-2'
      }
    },
    habilitarBtn() {
      return this.data_imagenes !== '' && this.data_imagenes.ext === 'jpg'
    },
  }
  
}
</script>

